<template>
    <comp-form title="选品分组" ref="comp_form" submit-api="/gateway/apps/o2o/api/o2o/platform/goods/addCategory" edit-api="/gateway/apps/o2o/api/o2o/platform/goods/updateCategory" id-key="categoryId" @on-submit="onReset" height="auto">
        <template v-slot:form-box="data">
            <ElemFormItem title="分组名称" name="categoryName">
                <template v-slot:default="item">
                    <Input :style="item.cfg.style" maxlength="20" v-model="data.form.categoryName" :placeholder="item.cfg.ph"></Input>
                </template>
            </ElemFormItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../../jointly/components/CompForm.vue"
import ElemFormItem from "../../../jointly/components/elem-form-item.vue"

export default {
    components: { CompForm, ElemFormItem },

    methods: {
        /**
         * 打开表单窗口
         */
        open(id, data) {
            this.$refs.comp_form.open(id, data)
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },
    },
}
</script>
